
import { Vue, Options } from 'vue-class-component'
import { useI18n } from 'vue-i18n'
import LanguageBox from '@/components/dialogs/lang-dialog-view.vue'
import { Action, Getter } from 'vuex-class'
import { EventBuss } from '@/utils/constants'
// import { Action, Getter } from 'vuex-class'

@Options({
    components: {
        LanguageBox
    }
})
export default class SelecLine extends Vue {
    @Getter('lang') lang!: string

    @Action('showLoading') showLoading!: () => void
    @Action('hideLoading') hideLoading!: () => void
    @Action('pingServer') pingServer!: (params: {
        line: number
        url: string
    }) => Promise<string>
    @Action('setLine') setLine!: (num: number) => void
    @Action('setLineStr') setLineStr!: (strLine: string) => void

    iN = useI18n().t
    line1 = false
    line2 = false
    line3 = false
    serverStr = ''
    showLangbox = false

    clickLine(line: number): void {
        if (line === 1 && !this.line1) return
        else if (line === 2 && !this.line2) return
        else if (line === 3 && !this.line3) return

        this.setLine(line)
        this.$router.push({ name: 'login' })
    }

    mounted() {
        this.aPingServer(1)
        this.aPingServer(2)
        this.aPingServer(3)
    }

    enterLogin() {
        if (this.serverStr.length > 0) {
            let url = this.serverStr.replace(/\/$/, '')

            let appendHttp = true

            if (url.startsWith('https://')) {
                appendHttp = false
            } else if (url.startsWith('http://')) {
                appendHttp = false
            }

            if (appendHttp) url = `http://${url}`

            if (!this.isValidSevice(url)) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                this.emitter.emit(
                    EventBuss.TOAST_MESSAGE,
                    this.iN('serverselection.lineerror')
                )
                return
            }

            this.showLoading()

            this.pingServer({ line: -1, url })
                .then(response => {
                    this.hideLoading()
                    if (response === 'test ok') {
                        this.setLine(-1)
                        this.setLineStr(url)
                        this.$router.push({ name: 'login' })
                    } else {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        this.emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            this.iN('serverselection.lineerror')
                        )
                    }
                })
                .catch(() => {
                    this.hideLoading()
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    this.emitter.emit(
                        EventBuss.TOAST_MESSAGE,
                        this.iN('serverselection.lineerror')
                    )
                })
        }
    }

    isValidSevice(urlStr: string): boolean {
        const pattern = new RegExp(
            '^((ft|htt)ps?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?' + // port
            '(\\/[-a-z\\d%@_.~+&:]*)*' + // path
            '(\\?[;&a-z\\d%@_.,~+&:=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$',
            'i'
        )
        return pattern.test(urlStr)
    }

    async aPingServer(line: number) {
        const params = { line, url: '' }
        this.pingServer(params)
            .then(response => {
                if (response === 'test ok') {
                    if (line === 1) this.line1 = true
                    else if (line === 2) this.line2 = true
                    else if (line === 3) this.line3 = true
                } else {
                    if (line === 1) this.line1 = false
                    else if (line === 2) this.line2 = false
                    else if (line === 3) this.line3 = false
                }
            })
            .catch(() => {
                if (line === 1) this.line1 = false
                else if (line === 2) this.line2 = false
                else if (line === 3) this.line3 = false
            })
    }

    OnCLickedServer(): void {
        window.open(process.env.VUE_APP_CUSTUMER_SERVICE, '_blank')
    }
}
